import type { ThemeOptions } from '@mui/material';
import { components } from './components';
import { coreThemeConstants } from './coreThemeConstants';
import { Tokens } from './designTokens';
import { disableTransitions } from './disableTransitions';
import { paletteLight } from './paletteLight';
import { generateTypography } from './typography';

export const coreThemeProps = {
  borderRadius: {
    /* eslint-disable sort-keys-fix/sort-keys-fix */
    // 4px
    xs: '0.25rem',
    // 8px - same as theme.shape.borderRadius
    sm: '0.5rem',
    // 16px
    md: '1rem',
    // 24px
    lg: '1.5rem',
    // 60px
    xl: '3.75rem',
    // full circles - should be just enough to create full circles for a large button - which is 52px
    // if it is too much it breaks the styling for a non-wrapped address
    cylinder: '1.7rem',
    /* eslint-enable sort-keys-fix/sort-keys-fix */
  },
  components,
  palette: paletteLight,
  shadows: [
    'none',
    coreThemeConstants.elevation.s,
    coreThemeConstants.elevation.m,
    coreThemeConstants.elevation.l,
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
  ],
  shape: {
    appHeaderHeight: '40px',
    borderRadius: 8, // 8px - needs to be a unitless number for use in sx props
    switch: {
      height: '1.5rem', // 24px,
      width: '2.25rem', // 36px,
    },
  },
  spacingForNavbar: '132px',
  themeName: 'WebUi',
  // apply 'transition: none' everywhere if disableTransitions is true
  transitions: disableTransitions ? { create: (): string => 'none' } : {},
  typography: generateTypography(Tokens.Mobile),
  zIndex: {
    // see: https://mui.com/material-ui/customization/default-theme/
    appBar: 1100,
    // drawer is used on swipeable dialogs
    drawer: 1300,
    // floatingBar needs to be above hub but below modal
    floatingBar: 1295,
    // hub needs to be below modal and above appBar
    hub: 1290,
    mobileStepper: 1000,
    // modal is used in non-swipeable dialogs, should be above drawer
    modal: 1350,
    snackbar: 1400,
    speedDial: 1050,
    tooltip: 1500,
  },
} satisfies ThemeOptions;
